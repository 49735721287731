import * as React from "react"

const ZeroData = () => {
  return (
    <p>
      No blog posts found matching this query.
    </p>
  )
}

export default ZeroData;
